import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import { bindMethods } from '../../../lib/utils';

class JoinForm extends React.Component {
  constructor(props) {
    super(props);
    bindMethods(this, 'handle');
  }

  handleChange(e) {
    const input = e.currentTarget;
    const state = {
      ...this.props.state,
      [input.name]: input.value
    };
    this.props.onChange(state);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.onSubmit(this.props.state);
  }

  render() {
    const { state, errors } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup validationState={errors.password ? 'error' : null}>
          <FormControl
            className="input-lg"
            type="password"
            name="password"
            value={state.password}
            placeholder="New Password"
            onChange={this.handleChange}
            required
          />
          <HelpBlock>{errors.password || 'Minimum 8 symbols'}</HelpBlock>
        </FormGroup>
        <FormGroup validationState={errors.passwordConfirm ? 'error' : null}>
          <FormControl
            className="input-lg"
            type="password"
            name="passwordConfirm"
            value={state.passwordConfirm}
            placeholder="Confirm Password"
            onChange={this.handleChange}
            required
          />
          {errors.passwordConfirm && <HelpBlock>{errors.passwordConfirm}</HelpBlock>}
        </FormGroup>
        <Button type="submit" bsSize="large" bsStyle="primary" block>Join</Button>
      </Form>
    );
  }
}

JoinForm.defaultProps = {};

JoinForm.propTypes = {
  state: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default JoinForm;
