import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import authActions from '../../../actions/auth.actions.jsx';
import { FormGroup, FormControl, Button, HelpBlock, Alert, Nav, NavItem } from 'react-bootstrap';
import { bindMethods } from '../../../lib/utils.js';

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      isSignedUp: false,
      loading: false,
      email: props.email || '',
      password: '',
      name: '',
      accountType: 'personal',
      orgName: '',
      token: null
    };
    this.recaptcha = React.createRef();
    bindMethods(this, 'handle');
  }

  handleRecaptcha(token) {
    this.setState({ token, error: {} });
  }


  handleSubmit(e) {
    e.preventDefault();
    const { email, name, password, accountType, orgName, token } = this.state;

    this.setState({ loading: true });
    authActions
      .signUp(
        email,
        password,
        name,
        accountType === 'org' ? orgName : null,
        token
      )
      .then(() => {
        this.setState({
          loading: false,
          isSignedUp: true
        });
      })
      .catch((response) => {
        const error = response.error || response.data.error;
        this.recaptcha.current.reset();
        this.setState({
          loading: false,
          error,
          token: null
        });
      });
  }

  handleTypeChange(accountType) {
    this.setState({ accountType });
  }

  handleFieldChange(e) {
    const input = e.currentTarget;
    this.setState({
      error: {},
      [input.name]: input.value
    });
  }

  render() {
    const { email, name, password, error, loading, isSignedUp, accountType, orgName } = this.state;
    const errorDetails = error.details || {};
    const errorMessages = errorDetails.messages || {};

    if (isSignedUp) {
      return (
        <p>
          Thank you for signing up. We sent you an email confirmation link, please confirm and login.
          <br />
          <br />
          <a href="/login">Sign in</a> now, but you won't receive our awesome notifications until you confirm your email
        </p>
      );
    }

    let orgInput = null;
    if (accountType === 'org') {
      orgInput = (
        <FormGroup validationState={errorMessages.orgName ? 'error' : undefined}>
          <FormControl
            name="orgName"
            onChange={this.handleFieldChange}
            value={orgName}
            disabled={loading}
            className="input-lg"
            type="text"
            placeholder="Organization Name"
            required
          />
          <HelpBlock>{errorMessages.orgName}</HelpBlock>
        </FormGroup>
      );
    }

    return (
      <form className="signup-form" onSubmit={this.handleSubmit}>
        <Nav
          bsStyle="tabs"
          justified
          activeKey={accountType}
          onSelect={this.handleTypeChange}
        >
          <NavItem eventKey="personal" title="Personal account">
            Personal
          </NavItem>
          <NavItem eventKey="org" title="Organization account">
            Organization
          </NavItem>
        </Nav>
        {orgInput}
        <FormGroup>
          <FormControl
            name="name"
            onChange={this.handleFieldChange}
            value={name}
            disabled={loading}
            className="input-lg"
            type="text"
            placeholder="Name"
            required
          />
        </FormGroup>
        <FormGroup validationState={errorMessages.email ? 'error' : undefined}>
          <FormControl
            className="input-lg"
            type="email"
            name="email"
            placeholder="Email"
            onChange={this.handleFieldChange}
            value={email}
            disabled={loading}
            required
          />
          <HelpBlock>{errorMessages.email ? errorMessages.email[0] : ''}</HelpBlock>
        </FormGroup>
        <FormGroup validationState={errorMessages.password ? 'error' : undefined}>
          <FormControl
            className="input-lg"
            type="password"
            name="password"
            value={password}
            placeholder="Password"
            onChange={this.handleFieldChange}
            disabled={loading}
            required
          />
          <HelpBlock>Minimum 8 symbols</HelpBlock>
        </FormGroup>
        <div className="recaptcha-container">
          <div className="recaptcha-element">
            <ReCAPTCHA
              ref={this.recaptcha}
              size="normal"
              sitekey="6LdoXLwUAAAAAGcZs6IHcwbxNKK9LQza7SQWKqst"
              onChange={this.handleRecaptcha}
            />
          </div>
        </div>
        {errorMessages.generic && <Alert bsStyle="danger">{errorMessages.generic}</Alert>}
        <Button disabled={loading} type="submit" bsSize="large" bsStyle="primary" block>
          Create account
        </Button>
        <div className="form-group m-top15">
          <a href="/login">Already signed up?</a>
        </div>
      </form>
    );
  }
}

SignupForm.propTypes = {
  email: PropTypes.string
};

export default SignupForm;
