import React from 'react';
import AuthPage from './AuthPage.jsx';
import SetNewPasswordForm from '../set-new-password-form.jsx';

function SetNewPasswordPage({ token }) {
  return (
    <AuthPage title="Set new password">
      <SetNewPasswordForm token={token} />
    </AuthPage>
  );
}

export default SetNewPasswordPage;

if (module.hot) {
  module.hot.accept([
    './password-reset-form.jsx',
    './auth-page.jsx'
  ], function() {
    // TODO flux add actions
  });
}
