import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col, Panel } from 'react-bootstrap';
import AuthNavbar from '../auth-navbar.jsx';

function AuthPage({ title, children }) {
  return (
    <div>
      <AuthNavbar />
      <Grid className="form-container">
        <Row>
          <Col xs={12} sm={6} smOffset={3} md={4} mdOffset={4}>
            <Panel>
              <div className="panel-desc">{title}</div>
              <hr />
              {children}
            </Panel>
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

AuthPage.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
};

export default AuthPage;
