import React from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import AuthPage from './AuthPage.jsx';
import request from '../../lib/request';
import LoadingSpinner from '../LoadingSpinner';
import Button from '../common/Button';
import { bindMethods } from '../../lib/utils';
import styles from './VerifyEmailPage.module.less';


class VerifyEmailPage extends React.Component {
  constructor(props) {
    super(props);
    bindMethods(this, 'handle');
    this.state = {
      status: 'loading'
    };
  }

  componentDidMount() {
    const { uid, token } = qs.parse(window.location.search);
    request
      .get('/api/v2/users/verify-email', { uid, token })
      .then(result => {
        this.setState({
          status: result.status,
          nextStep: result.nextStep
        });
      })
      .catch(err => {
        this.setState({ status: 'error' });
      });
  }

  handleDashboard() {
    window.location.href = '/';
  }

  handleLogin() {
    window.location.href = '/login';
  }

  render() {
    const { status, nextStep } = this.state;
    let actionButton;
    let actionName;
    switch (nextStep) {
      case 'dashboard':
        actionButton = (
          <Button className={styles.cta} skin="primary" onClick={this.handleDashboard}>
            Go to Dashborad
          </Button>
        );
        actionName = 'dashboard';
        break;
      case 'login':
        actionButton = (
          <Button className={styles.cta} skin="primary" onClick={this.handleLogin}>
            Sign In
          </Button>
        );
        actionName = 'login';
        break;
      default:
        actionButton = null;
        actionName = '';
    }

    switch (status) {
      case 'loading':
        return (
          <AuthPage title="Email Verification">
            <div className={styles.contentLoading}>
              <p>Verifying your email...</p>
              <LoadingSpinner position="fixed" />
            </div>
          </AuthPage>
        );
      case 'unnecessary':
        return (
          <AuthPage title="Email Verification">
            <p>
              Your email was already verified. You can now proceed to {actionName}.
            </p>
            <p className="small">
              Note: please refresh any Letter Friend app tabs that were previously open on your browser.
            </p>
            {actionButton}
          </AuthPage>
        );
      case 'success':
        return (
          <AuthPage title="Email Verification">
            <p>
              Email verification complete. You can now proceed to {actionName}.
            </p>
            <p className="small">
              Note: please refresh any Letter Friend app tabs that were previously open on your browser.
            </p>
            {actionButton}
          </AuthPage>
        );
      case 'error':
        return (
          <AuthPage title="Email Verification">
            <p className="text-danger">
              Email verification failed. Please login and resend verification email, or contact support.
            </p>
            <Button className={styles.cta} skin="primary" onClick={this.handleLogin}>
              Sign In to Resend
            </Button>
          </AuthPage>
        );
      default:
        return (
          <AuthPage title="Email Verification">
            <p>Something went wrong. Please contact support.</p>
          </AuthPage>
        );
    }
  }
}

VerifyEmailPage.propTypes = {
  query: PropTypes.object,
  page: PropTypes.string.isRequired
};

export default VerifyEmailPage;
