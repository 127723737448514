import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControl, Button } from 'react-bootstrap';
import authActions from '../../actions/auth.actions.jsx';
import { bindMethods } from '../../lib/utils';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      email: props.email || '',
      password: ''
    };
    bindMethods(this, 'on');
  }

  onSubmit(e) {
    e.preventDefault();
    const { email, password } = this.state;
    authActions.login(email, password)
      .then((data) => {
        if (data.error) {
          return this.setState({
            error: data.message
          });
        }
        window.location.pathname = '/';
      })
      .catch((err) => {
        console.log(err.stack);
        this.setState({
          error: 'Something went wrong! Please, contact support'
        });
      });
  }

  onChange(e) {
    const input = e.currentTarget;
    this.setState({
      error: null,
      [input.name]: input.value
    });
  }

  render() {
    const loginError = this.state.error ?
      <span className="text-danger">{this.state.error}</span> : '';
    return (
      <form onSubmit={this.onSubmit}>
        <FormGroup>
          <FormControl
            className="input-lg"
            type="email"
            name="email"
            placeholder="Email"
            onChange={this.onChange}
            value={this.state.email}
          />
        </FormGroup>
        <FormGroup>
          <FormControl
            className="input-lg"
            type="password"
            name="password"
            onChange={this.onChange}
            value={this.state.password}
            placeholder="Password"
          />
        </FormGroup>
        {loginError}
        <Button type="submit" bsSize="large" bsStyle="primary" block>Sign in</Button>
        <div className="form-group m-top15">
          <a href="signup">Create an account</a>
          <a href="password-reset" className="pull-right">Recover password</a>
        </div>
      </form>
    );
  }
}

LoginForm.propTypes = {
  email: PropTypes.string,
};

export default LoginForm;
