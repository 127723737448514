import React from 'react';
import PropTypes from 'prop-types';
import AuthPage from './AuthPage.jsx';

function AccountLockedPage({ currentUser }) {
  return (
    <AuthPage title="Account is locked">
      <p>
        {currentUser.lockReason || 'Your account is locked, please contact us: hello@letterfriend.com or 201-500-7766'}
      </p>
      <a href="/logout" className="btn-block btn btn-lg btn-primary">Logout</a>
    </AuthPage>
  );
}

AccountLockedPage.propTypes = {
  currentUser: PropTypes.object.isRequired
};

export default AccountLockedPage;
