import { users } from '../lib/api';

export default {
  /**
   * Create user account
   * @param email {string} user email
   * @param password {string} user password
   * @param name {string} user full name
   * @param orgName {string} organization name
   * @param recaptchaToken {string} token provided by recaptcha library
   * @returns {Promise}
   */
  signUp(email, password, name, orgName, recaptchaToken) {
    return users.create(email, password, name, orgName, recaptchaToken);
  },

  login(email, password) {
    return users.login(email, password);
  },

  logout() {
    users
      .logout()
      .then(() => {
        window.location.pathname = '';
      });
  },

  resetPassword(email) {
    return users.resetPassword(email);
  },

  setNewPassword(password, token) {
    return users.setNewPassword(password, token);
  }
};
