import React from 'react';
import AuthPage from '../AuthPage.jsx';
import SignupForm from './SignupForm.jsx';

function SignupPage() {
  return (
    <AuthPage title="Sign up">
      <SignupForm />
    </AuthPage>
  );
}

export default SignupPage;
