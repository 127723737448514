import React from 'react';
import PropTypes from 'prop-types';
import { Button as BsButton } from 'react-bootstrap';
import { Link } from 'react-router';
import { bindMethods } from '../../lib/utils';

class Button extends React.Component {
  constructor(props) {
    super(props);
    bindMethods(this, 'handle');
  }

  handleClick(e) {
    const { value, onClick } = this.props;
    if (!onClick) {
      return;
    }
    e.preventDefault();
    onClick(value);
  }

  render() {
    const { children, skin, size, to, ...rest } = this.props;
    let componentClass;
    if (to) {
      componentClass = Link;
    }
    return (
      <BsButton
        {...rest}
        bsStyle={skin}
        bsSize={size}
        componentClass={componentClass}
        to={to}
        onClick={this.handleClick}
      >
        {children}
      </BsButton>
    );
  }
}

Button.defaultProps = {
  skin: 'default',
  type: 'button'
};

Button.propTypes = {
  skin: PropTypes.oneOf(['default', 'primary', 'success', 'info', 'warning', 'danger', 'link']),
  size: PropTypes.oneOf(['xs', 'sm', 'lg']),
  to: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  value: PropTypes.any,
  onClick: PropTypes.func
};

export default Button;
