import queryString from 'query-string';

function processResponse(response) {
  return Promise
    .resolve()
    .then(() => {
      if (response.headers.get('content-type').indexOf('application/json') > -1) {
        return response.json();
      }
      return response.text();
    })
    .then((data) => ({ status: response.status, data }))
    .then((r) => {
      if (r.status >= 200 && r.status < 300) {
        return r.data;
      }
      const err = new Error('[' + r.status + '] Error');
      err.status = r.status;
      err.response = r.data;
      throw err;
    });
}

const request = {
  get(url, query = {}, options = {}) {
    const q = {...query};
    if(q.filter){
      q.filter = JSON.stringify(q.filter);
    }
    return fetch(url + '?' + queryString.stringify(q), {
      method: 'GET',
      credentials: options.credentials || 'include'
    }).then(processResponse);
  },
  patch(url, payload = {}, options = {}) {
    return fetch(url, {
      method: 'PATCH',
      credentials: options.credentials || 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }).then(processResponse);
  },
  post(url, payload = {}, options = {}) {
    return fetch(url, {
      method: 'POST',
      credentials: options.credentials || 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }).then(processResponse);
  },
  delete(url, options = {}) {
    return fetch(url, {
      method: 'DELETE',
      credentials: options.credentials || 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(processResponse);
  }
};

export default request;
