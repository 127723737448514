import React from 'react';

function AuthNavbar() {
  return (
    <header>
      <nav className="navbar navbar-default navbar-transparent">
        <div className="container">
          <div className="navbar-header">
            <button
              className="navbar-toggle"
              data-target="#top-navbar"
              data-toggle="collapse"
              type="button">
              <span className="sr-only">Toggle navigation</span>
              <i className="ion ion-ios7-gear-outline"></i>
            </button>
            {/* <a className="navbar-brand logo" href="#">
                             LetterFriend.com
                             </a> */}
          </div>
          <div className="collapse navbar-collapse" id="top-navbar">
            <ul className="nav navbar-nav navbar-right">
              {/*<li><a href="page_home.html"><i className="fa fa-angle-left"></i> Back to Home</a></li>*/}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default AuthNavbar;
