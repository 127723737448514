import React, { Component } from 'react';
import authActions from '../actions/auth.actions.jsx';
import { FormGroup, FormControl, Button } from 'react-bootstrap';
import { bindMethods } from '../lib/utils';

class PasswordResetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mailed: false,
      email: ''
    };
    bindMethods(this, 'on');
  }

  onSubmit(e) {
    e.preventDefault();
    const { email } = this.state;
    authActions.resetPassword(email)
      .then(() => this.setState({ mailed: true }));
  }

  onChange(e) {
    const input = e.currentTarget;
    this.setState({
      [input.name]: input.value
    });
  }

  render() {
    const { email, mailed } = this.state;
    if (mailed) {
      return <span>We send you link for password reset. Check your email.</span>;
    }
    return (
      <div>
        <form onSubmit={this.onSubmit}>
          <FormGroup>
            <FormControl
              className="input-lg"
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={this.onChange}
            />
          </FormGroup>
          <Button type="submit" bsSize="large" bsStyle="primary" block>Reset my password</Button>
        </form>
        <div className="form-group m-top15">
          <a href="/login">Sign in</a>
        </div>
      </div>
    );
  }
}

export default PasswordResetForm;
