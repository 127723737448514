import React from 'react';
import PropTypes from 'prop-types';
import AuthPage from './AuthPage.jsx';
import LoginForm from './LoginForm.jsx';

function LoginPage(props) {
  const formLogin = !!(props.query && props.query.from === 'verified');
  return (
    <AuthPage title="Sign in">
      {formLogin ? <p>Now you can login to your account</p> : ''}
      <LoginForm />
    </AuthPage>
  );
}

LoginPage.propTypes = {
  query: PropTypes.object,
  page: PropTypes.string.isRequired
};

export default LoginPage;
