import * as Sentry from "@sentry/react";

window.self = window.self || {};
import 'whatwg-fetch';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import { render } from 'react-dom';
// import './styles/fonts.less';
import 'bootstrap/less/bootstrap.less';
import './styles/styles.less';
import StaticPage from './components/StaticPage/index';

if (window.location.hostname === 'app.letterfriend.com') {
  Sentry.init({
    dsn: 'https://eabea84f93c545e39b78d2c00a7e60ab@o469955.ingest.sentry.io/5500086',
    environment: window.location.hostname
  });
}

const data = window.DATA;

render(<StaticPage page={data.page} {...data} />, document.getElementById('app'));
