import React, { Component } from 'react';
import authActions from '../actions/auth.actions.jsx';
import PropTypes from 'prop-types';
import { FormGroup, FormControl, Button } from 'react-bootstrap';
import { bindMethods } from '../lib/utils';

class SetNewPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: ''
    };
    bindMethods(this, 'on');
  }

  onSubmit(e) {
    const { password } = this.state;
    e.preventDefault();
    authActions.setNewPassword(password, this.props.token)
      .then(() => {
        window.location = '/';
      });
  }

  onChange(e) {
    const input = e.currentTarget;
    this.setState({
      [input.name]: input.value
    });
  }

  render() {
    const { password } = this.state;
    return (
      <form onSubmit={this.onSubmit}>
        <FormGroup>
          <FormControl
            name="password"
            className="input-lg"
            type="password"
            placeholder="New password"
            value={password}
            onChange={this.onChange}
          />
        </FormGroup>
        <Button type="submit" bsSize="large" bsStyle="primary" block>Set my new password</Button>
      </form>
    );
  }
}

SetNewPasswordForm.propTypes = {
  token: PropTypes.string.isRequired
};

export default SetNewPasswordForm;
