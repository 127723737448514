/*eslint-disable */

import { isUndefined, forOwn, isObject, omit } from 'lodash';

export const settings = {
  fetch: function() {
    return get('/settings');
  },

  update: updateBuilder('settings')
};

export const users = {
  getCurrentUser(filter) {
    const filterStr = JSON.stringify(filter || { include: ['org'] });
    return get('/users/me', {
      filter: filterStr
    });
  },
  findById(id, filter = null) {
    const filterStr = JSON.stringify(filter || { include: ['employee', 'accountRep', 'curators', 'org'] });
    return get('/users/' + id, {
      filter: filterStr
    });
  },
  toggleSubscription(id, name, value) {
    return put(`/users/${id}/toggleSubscription`, { [name]: value });
  },
  resendEmailVerify() {
    return post('/users/resendEmailVerify');
  },
  addCredits(userId, count, description) {
    return post(`/users/${userId}/addCredits`, { count, description });
  },

  updatePassword(password) {
    return post('/users/updatePassword', { password });
  },

  list: function() {
    return get('/users/', {
      filter: JSON.stringify({ include: ['employee', 'accountRep', 'curators'] })
    });
  },

  find: function(filter = {}) {
    return get('/users/', {
      filter: JSON.stringify(filter)
    });
  },

  create: function(email, password, username, orgName, recaptchaToken) {
    return post('/users/', {
      email: email,
      password: password,
      name: username,
      orgName: orgName,
      recaptchaToken
    });
  },

  login: function(email, password) {
    return fetch('/api/v2/login', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        password: password
      })
    })
      .then(toJson);
  },

  logout: function() {
    return post('/users/logout');
  },

  resetPassword: function(email) {
    return post('/users/reset', { email });
  },

  setNewPassword: function(password, token) {
    return post('/users/setNewPassword', {
      password: password,
      accessToken: token
    });
  },

  getLetters: function(id) {
    return get('/users/' + id + '/letters/', {
      filter: JSON.stringify({ include: ['user', 'files'] })
    });
  },

  fetchOrgs: function(uid) {
    return get('/users/' + uid + '/orgs');
  },

  update: function(id, data) {
    return put('/users/' + id, data);
  },

  createDevToken(id, { ttl }) {
    return post(`/users/${id}/devTokens`, { ttl });
  },

  fetchDevTokens(id) {
    return get(`/users/${id}/devTokens`);
  },

  resetDevToken(id) {
    return post(`/users/${id}/devToken/reset`);
  }
};

export const autoAddCredits = {
  get(ruleId) {
    const [ownerType, id] = ruleId.split(':');
    return get(`/${ownerType}/${id}/autoAddCredits`);
  },
  save(ruleId, data) {
    const [ownerType, id] = ruleId.split(':');
    const method = data.id ? put : post;
    return method(`/${ownerType}/${id}/autoAddCredits`, data);
  },
  remove(ruleId) {
    const [ownerType, id] = ruleId.split(':');
    return del(`/${ownerType}/${id}/autoAddCredits`);
  }
};

export const userLog = {
  get(filter) {
    return get(`/UserLogs`, { filter });
  }
};

export const templates = {
  find: (params = {}) => get('/v2/templates/my', params),
  create: tpl => post('/v2/templates', tpl),
  remove: id => del('/v2/templates/' + id)
};

export const letters = {
  findById(letterId) {
    return get('/letters/' + letterId, {
      filter: JSON.stringify({ include: ['user', 'files', 'org'] })
    });
  },

  list(params = {}) {
    return get('/letters/my', params);
  },

  listForOrg(orgId) {
    return get('/letters/my', {
      filter: JSON.stringify({ where: { orgId: orgId } })
    });
  },

  create(letter) {
    return post('/letters', letter);
  },

  remove(id) {
    return del('/letters/' + id);
  },

  update(id, data) {
    return put('/letters/' + id, data);
  },

  setFlag(id, data) {
    return put('/letters/' + id + '/setFlag', data);
  },

  count(where) {
    var params = {
      where: JSON.stringify(where)
    };

    return get('/letters/count', params);
  },

  countRecipients(where) {
    var params = {
      where: JSON.stringify(where)
    };

    return get('/letters/countRecipients', params);
  },

  sumCost(where) {
    var params = {
      where: JSON.stringify(where)
    };

    return get('/letters/sumCost', params);
  },

  summary(orgId) {
    if (orgId) {
      return get(`/orgs/${orgId}/summary`);
    }

    return get('/letters/summary');
  },

  removeFile(id, fid) {
    return del(`/letters/${id}/files/${fid}`);
  },

  statistics(filter, sampling, start, end) {
    filter = JSON.stringify(filter);

    return get(`/letters/statistics`, {
      filter, sampling, start, end
    });
  },

  changeStatus(id, status, data = {}) {
    return put(`/letters/${id}/changeStatus`, {
      status,
      data
    });
  },

  validateAddress(data) {
    return post(`/addresses/uspsValidation`, data);
  },
  getPlaceInfo(placeId, elementId) {
    const service = new google.maps.places.PlacesService(document.getElementById(elementId));
    return new Promise((resolve, reject) => {
      service.getDetails({ placeId }, (place, status) => {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          return resolve(place);
        }
        return resolve(null);
      });
    });

    return fetch('https://maps.googleapis.com/maps/api/place/details/json?' + serialize({
      placeid: id,
      key: 'AIzaSyBR1DrmxjSq7rpI-Gr-2LxSiLt6X3nIPWY'
    }))
      .then(checkError)
      .then(toJson);
  }
};

export const orgs = {
  get: function(id) {
    return get('/orgs/' + id);
  },

  list: function() {
    return get('/orgs', {
      filter: JSON.stringify({ include: ['owner', 'members', 'curators'] })
    });
  },

  create: function(org) {
    return post('/orgs', org);
  },

  update: updateBuilder('orgs'),

  addMember: function addOrgMember(orgId, email) {
    return put('/orgs/' + orgId + '/members/rel/' + email);
  },

  removeMember: function(orgId, userId) {
    return del('/orgs/' + orgId + '/members/rel/' + userId);
  }
};

export const join = {
  getInvite(code) {
    return get(`/v2/invites/code/${code}`);
  },

  signup(code, password) {
    return post(`/v2/invites/code/${code}`, { code, password });
  }
};

//apis build
function updateBuilder(name) {
  return function(id, data) {
    return put('/' + name + '/' + id, data);
  };
}


// methods
var root = '/api';

function del(url) {
  return makeRequest(url, 'delete', {});
}

function post(url, data) {
  return makeRequest(url, 'post', data);
}

function put(url, data) {
  const payload = data ? omit(data, 'id') : {};
  return makeRequest(url, 'put', payload);
}

function get(url, params) {
  const options = {
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return fetch(root + url + '?' + serialize(params), options)
    .then(checkError)
    .then(toJson);
}

function checkError(res) {
  // check loost auth
  if (res.status === 401) {
    const options = {
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    return fetch('/api/users/me', options)
      .then(toJson)
      .then(user => {
        if (!user) {
          window.location = '/login';
        }

        return res.json()
          .then(data => Promise.reject({
            status: res.status,
            data: data
          }));
      });
  }
  if (res.status < 200 || res.status >= 300) {
    return res.json()
      .then(data => {
        if (res.status === 403 && data.reason === 'account_lock') {
          window.location = '/locked';
          return;
        }
        return Promise.reject({
          status: res.status,
          data: data
        });
      });
  }

  return res;
}

function toJson(res) {
  if (res.status === 204) {
    return null;
  }
  return res.json();
}

function makeRequest(url, method, data) {
  return fetch(root + url, {
    method: method,
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(checkError)
    .then(toJson);
}

function serialize(params) {
  const str = [];
  forOwn(params, (val, key) => {
    if (!isUndefined(val)) {
      if (isObject(val)) {
        val = JSON.stringify(val);
      }
      str.push(encodeURIComponent(key) + '=' + encodeURIComponent(val));
    }
  });

  return str.join('&');
}

/*eslint-enable */
