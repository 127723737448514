import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { bindMethods } from '../../../lib/utils';
import { join as api } from '../../../lib/api';
import Loading from '../../Loading';
import JoinForm from './JoinForm';
import AuthPage from '../AuthPage';
import Button from '../../common/Button';

class JoinPage extends React.Component {
  constructor(props) {
    super(props);
    bindMethods(this, 'handle');
    this.state = {
      invite: null,
      formState: {
        password: '',
        passwordConfirm: ''
      },
      errors: {},
      loading: true
    };
  }

  componentDidMount() {
    const { code } = this.props.params;
    api.getInvite(code).then((invite) => {
      if (invite) {
        this.setState({
          invite,
          loading: false
        });
      } else {
        this.setState({
          invite: false,
          errors: { generic: 'Invitation does not exist or was cancelled' },
          loading: false
        });
      }

    })
      .catch(() => {
        this.setState({
          errors: { generic: 'Something went wrong, please contact support' },
          loading: false
        });
      });
  }

  handleChange(formState) {
    this.setState({
      formState,
      errors: {}
    });
  }

  handleSubmit(formState) {
    if (formState.password !== formState.passwordConfirm) {
      this.setState({
        errors: { passwordConfirm: 'Password and confirmation does not match' }
      });
      return;
    }
    if (formState.password.length < 8) {
      this.setState({
        errors: { password: 'Minimum 8 symbols' }
      });
      return;
    }
    const { code } = this.props.params;
    const { password } = formState;
    api
      .signup(code, password)
      .then(() => {
        window.location.pathname = '/';
      })
      .catch(e => {
        let errors = {
          generic: 'Something went wrong, please contact support'
        };
        if (e.data) {
          if (e.data.data) {
            errors = e.data.data;
          } else if (e.data.message) {
            errors = {
              generic: e.data.message
            };
          }
        }
        this.setState({
          errors
        });
      });
  }


  render() {
    const { invite, formState, errors, loading } = this.state;
    let genericError = null;
    if (errors.generic) {
      genericError = <Alert bsStyle="danger">{errors.generic}</Alert>;
    }
    if (!invite) {
      if (loading) {
        return <Loading />;
      }
      if (genericError) {
        return (
          <AuthPage title="Error">{genericError}</AuthPage>
        );
      }
      return null;
    }
    if (invite.status === 'accepted') {
      return (
        <AuthPage title={`Join ${invite.orgName}`}>
          <div className="form-group">
            <p>This invitation is already accepted. Please proceed to <strong>Sign In</strong> page and use your email and password.</p>
          </div>
          <Button className="btn-block" size="lg" skin="primary" href="/login">Sign In</Button>
        </AuthPage>
      );
    }
    return (
      <AuthPage title={`Join ${invite.orgName}`}>
        <p>You were invited by <strong>{invite.initiatorName}</strong> to join <strong>{invite.orgName}</strong> on Letterfriend</p>
        <p>Please create secure password for your account.</p>
        {genericError}
        <JoinForm
          state={formState}
          errors={errors}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
        />
      </AuthPage>
    );
  }
}

JoinPage.propTypes = {
  params: PropTypes.object,
  query: PropTypes.object,
  page: PropTypes.string,
  token: PropTypes.string,
  currentUser: PropTypes.object
};

export default JoinPage;
