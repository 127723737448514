/*
Font-awesome icon wrapper
 */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function Fa(props) {
  const style = {
    verticalAlign: props.align || 'inherit'
  };
  const cls = {
    fa: true,
    'fa-spin': props.spin,
    'fa-fw': props.fw
  };

  cls['fa-' + props.icon] = true;

  if (props.size) cls['fa-' + props.size] = true;
  if (props.flip) cls['fa-flip-' + props.flip] = true;
  return (
    <i className={classNames(cls)} style={style} />
  );
}


Fa.propTypes = {
  icon: PropTypes.string.isRequired,
  fw: PropTypes.bool,
  spin: PropTypes.bool,
  flip: PropTypes.string,
  align: PropTypes.string,
  size: PropTypes.oneOf(['lg', '2x', '3x', '4x', '5x'])
};
export default Fa;
