import React from 'react';
import PropTypes from 'prop-types';
import SignupPage from './SignupPage';
import NotFound from './NotFound';
import LoginPage from './LoginPage';
import PasswordResetPage from './PasswordResetPage';
import SetNewPasswordPage from './SetNewPasswordPage';
import AccountLockedPage from './AccountLockedPage';
import JoinPage from './JoinPage';
import VerifyEmailPage from './VerifyEmailPage';

const pages = {
  signup: SignupPage,
  'not-found': NotFound,
  login: LoginPage,
  'password-reset': PasswordResetPage,
  'set-new-password': SetNewPasswordPage,
  locked: AccountLockedPage,
  join: JoinPage,
  'verify-email': VerifyEmailPage
};

const wind = typeof window !== 'undefined' ? window : null;

function StaticPage(props) {
  let { params, query, page, token, currentUser } = props;
  const Page = pages[page];
  token = token || wind && wind.DATA && wind.DATA.token;

  return <Page params={params} query={query} page={page} token={token} currentUser={currentUser} />;
}

StaticPage.propTypes = {
  params: PropTypes.object,
  query: PropTypes.object,
  page: PropTypes.string,
  token: PropTypes.string,
  currentUser: PropTypes.object
};

export default StaticPage;
