import React from 'react';
import AuthPage from './AuthPage.jsx';
import PasswordResetForm from '../password-reset-form.jsx';

function PasswordResetPage() {
  return (
    <AuthPage title="Password reset">
      <PasswordResetForm />
    </AuthPage>
  );
}

export default PasswordResetPage;

if (module.hot) {
  module.hot.accept([
    './password-reset-form.jsx',
    './auth-page.jsx'
  ], function () {
    //TODO flux add actions
  });
}
