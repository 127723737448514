import { filter, includes } from 'lodash';
import business from 'moment-business';
import moment from 'moment-timezone';

export function bindMethods(object, prefix) {
  let propNames = Object.getOwnPropertyNames(Object.getPrototypeOf(object));
  if (prefix) {
    propNames = filter(propNames, (p) => p.indexOf(prefix) === 0);
  } else {
    propNames = filter(propNames, (p) => !includes(['constructor', 'render'], p));
  }
  propNames
    .forEach(prop => {
      if (typeof object[prop] === 'function') {
        object[prop] = object[prop].bind(object);
      }
    });
}

export function isShallowEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let i = 0; i < keys1.length; i++) {
    const key = keys1[i];
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  for (let i = 0; i < keys2.length; i++) {
    const key = keys2[i];
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

export function getTurnaroundDays(queuedAt, mailedAt) {
  const startDate = moment(queuedAt).tz('America/New_York');
  const startDate330pm = startDate.clone().hours(15).minutes(30);
  const endDate = moment(mailedAt || new Date()).tz('America/New_York');
  const endDate5pm = endDate.clone().hours(17);
  const isOpen = !mailedAt;
  const queuedOnWeekend = startDate.day() === 0 || startDate.day() === 6;
  const endDateOnWeekend = endDate.day() === 0 || endDate.day() === 6;
  let count = business.weekDays(startDate.clone().startOf('day'), endDate.clone().startOf('day')) + 1;
  if (!queuedOnWeekend && startDate > startDate330pm) {
    count--;
  }
  if (!endDateOnWeekend && isOpen && (endDate > endDate5pm)) {
    count ++;
  }

  return count;
}
