import React from 'react';
import PropTypes from 'prop-types';
import './style.less';
import Loading from '../Loading';

export default function LoadingSpinner({ visible, position }) {
  if (!visible) {
    return null;
  }

  return (
    <div className={`loading-spinner position-${position}`}>
      <Loading />
    </div>
  );
}
LoadingSpinner.defaultProps = {
  visible: true,
  position: 'absolute'
};

LoadingSpinner.propTypes = {
  visible: PropTypes.bool,
  position: PropTypes.oneOf(['fixed', 'absolute'])
};
